<style scoped>
.smallLogo {
  max-width: 200px;
  cursor: pointer;
}

.bigLogo {
  max-width: 600px;
  margin-left: 130px;
}

.footer-section {
  width: 100%;
}

.footer-links {
  border-top: 1px solid #273b7a;

  max-width: 900px;
  margin: auto;
  margin-top: 5px;
}

.footer-link {
  color: #273b7a;
  font-family: verdana, arial;
  font-size: 11px;
  margin-right: 20px;
  text-decoration: none;
}

.footer-margin {
  margin-top: 200px;
}

.footer-copy {
  font-size: 10px;
  font-family: verdana;
}

.share-button-cro {
  min-height: 0px;
  padding: 0px 0px 2px 0px;
}

.share-buttons-line{
  margin-top: 10px;
  margin-bottom: 10px;
}


</style>

<template>
  <v-app>
    <v-main>
      <v-container style="max-width: 900px" fluid>
        <v-layout class="d-none d-md-flex">
          <v-flex shrink>
            <v-img
              src="cronopista-900.png"
              class="mb-md-6 mb-2 smallLogo"
              v-bind:class="{ 'mt-16 bigLogo': initialSearch }"
              v-on:click="clear"
            ></v-img>
          </v-flex>
        </v-layout>
        <v-layout class="d-flex d-md-none" justify-center align-center>
          <v-flex shrink>
            <v-img
              src="cronopista-900.png"
              max-width="600"
              class="mb-md-6 mb-2"
              v-on:click="clear"
            ></v-img>
          </v-flex>
        </v-layout>
        

        <div v-if="!initialSearch" class="share-buttons-line">
          <twitter-button
            description="Diccionario de rimas via @cronopista"
            url="https://cronopista.com/"
            class="share-button--outline share-button-cro"
          />
          <facebook-button
            class="share-button--outline share-button-cro"
            description="Diccionario de rimas via @cronopista"
            url="https://cronopista.com/"
          />  
        </div>
        <div>
          <diccionario
            v-on:search-initiated="searchInitiated"
            v-on:definition="findDefinition"
            v-bind:definition="definition"
            v-bind:loading="loading"
            v-bind:results="results"
            v-bind:initial-search="initialSearch"
            v-bind:searchParams="searchParams"
          />
        </div>
        <div
          class="footer-section"
          v-bind:class="{ 'footer-margin': initialSearch }"
        >
         
          <div class="footer-links">
            <a href="https://play.google.com/store/apps/details?id=com.cronopista.diccionario.de.rimas" class="footer-link body-1"
              >Android app</a
            >
            <a href="https://cronopista.com/wordpress/" class="footer-link body-1"
              >PC</a
            >

            <a
              href="https://cronopista.com/wordpress/?page_id=35"
              class="footer-link body-1"
              >Contacto</a
            >
          </div>
          <div class="footer-copy">
            ©Eduardo Rodríguez Lorenzo 2005-{{ new Date().getFullYear() }}.
            Todos los derechos reservados.
          </div>
         
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import diccionario from "./components/diccionario";
import TwitterButton from "vue-share-buttons/src/components/TwitterButton";
import FacebookButton from "vue-share-buttons/src/components/FacebookButton";

export default {
  name: "App",

  components: {
    diccionario,
    TwitterButton,
    FacebookButton
  },
  mounted: function () {
    
    
    var thisC = this;
    window.addEventListener("hashchange", function () {
      thisC.processHashChange();
    });
    thisC.processHashChange();
  },
  methods: {
    clear() {
      window.location.hash = "";
    },
    processHashChange() {
      if (!window.location.hash) {
        this.results = {
          total: 0,
          words: [],
        };
        this.searchParams = {
          word: "",
          silables: "I",
          type: "c",
          orderBy: "R",
          beginning: "I",
          category: "I",
          page: 1
        };
        this.initialSearch = true;
        
        return;
      }

      var split = decodeURI(window.location.hash).substr(1).split("/");
      this.searchParams = {
        word: split[0],
        silables: split[1],
        type: split[2],
        orderBy: split[3],
        beginning: split[4],
        category: split[5],
        page: parseInt(split[6])
      };


      this.doSearch();
    },
    findDefinition(word) {
      this.definition = { waiting: true };
      axios({
        method: "get",
        url: this.host+"/dict-be/definition.php",
        params: {
          word: word,
        },
      })
        .catch(function (error) {
          this.definition.error = true;
          this.definition.lema = word;
          console.log(error);
        })
        .then((response) => {
          var fixedJson = this.fixDefinition(response.data);
          console.log(fixedJson);
          this.definition = fixedJson;
          this.definition.lema = word;
        });
    },
    fixDefinition(definition) {
      if (!definition.entry) {
        definition = { error: true };
        return definition;
      }
      if (!definition.entry.length) {
        definition.entry = [definition.entry];
      }
      for (var i = 0; i < definition.entry.length; i++) {
        if (!definition.entry[i].definition.length) {
          definition.entry[i].definition = [definition.entry[i].definition];
        }

        for (var j = 0; j < definition.entry[i].definition.length; j++) {
          //morf to String
          var morf = definition.entry[i].definition[j].morf;
          if (typeof morf !== "string") {
            var newMorf = "";
            for (var x = 0; x < morf.length; x++) {
              newMorf += morf[x] + " ";
            }
            definition.entry[i].definition[j].morf = newMorf;
          } else if (typeof morf !== "string") {
            definition.entry[i].definition[j].morf = false;
          }
          //ref
          if (typeof definition.entry[i].definition[j].ref !== "string") {
            definition.entry[i].definition[j].ref = false;
          }
          //def
          if (typeof definition.entry[i].definition[j].def !== "string") {
            definition.entry[i].definition[j].def = false;
          }
          //example
          if (
            definition.entry[i].definition[j].example &&
            typeof definition.entry[i].definition[j].example === "string"
          ) {
            definition.entry[i].definition[j].example = [
              definition.entry[i].definition[j].example,
            ];
          }
        }
      }
      for (j = 1; j < definition.entry.length; j++) {
        definition.entry[0].definition = definition.entry[0].definition.concat(
          definition.entry[j].definition
        );
      }
      definition.entry = definition.entry[0];

      //remove empty shit
      return definition;
    },
    searchInitiated(freshSearch) {
      
      if(freshSearch){
        this.searchParams.page=1;
      }
      window.location.hash =
        this.searchParams.word +
        "/" +
        this.searchParams.silables +
        "/" +
        this.searchParams.type +
        "/" +
        this.searchParams.orderBy +
        "/" +
        this.searchParams.beginning +
        "/" +
        this.searchParams.category +
        "/" +
        this.searchParams.page;
      console.log(this.searchParams.word);
    },
    doSearch() {
      this.loading = true;
      axios({
        method: "get",
        url: this.host+"/dict-be/search-rhyme.php",
        params: this.searchParams,
      })
        .catch(function (error) {
          console.log(error);
        })
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.initialSearch = false;
          this.results = response.data;
          if ("activeElement" in document){
            document.activeElement.blur();
          }

        });
    },
  },
  data: () => ({

    loading: false,
    initialSearch: true,
    definition: {},
    results: {
      total: 0,
      words: [],
    },
    searchParams: {
      word: "",
      silables: "I",
      type: "c",
      orderBy: "R",
      beginning: "I",
      category: "I",
      page: 1
    },
   host:""
  })
};
</script>