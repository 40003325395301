<style scoped>
.filterButton {
  text-transform: none;
}

.resultsSection {
  border-bottom: 1px solid #000a29 !important;
  color: #000a29 !important;
}

.word-block {
  cursor: pointer;
}

.lema {
  display: block;
  text-align: left;
  font-size: 18pt;
  margin-bottom: 15px;
  color: #00a;
  font-weight: bold;
}

.definition {
  text-align: left;
  margin-top: 10px;
}

.morf {
  font-weight: bold;
}

.etimology {
  margin-top: 10px;
}

.entry {
  display: block;
  text-align: left;
  font-family: "Noto Sans", sans-serif;
  font-size: 10pt;
}

.example {
  font-style: italic;
  color: #004a8d;
  padding-left: 2px;
}

ol {
  list-style: none;
  counter-reset: item;
  padding-left: 0px !important;
}
li {
  counter-increment: item;
  margin-bottom: 5px;
}
li:before {
  margin-right: 10px;
  content: counter(item);
  background: lightblue;
  border-radius: 100%;
  color: white;
  width: 1.2em;
  text-align: center;
  display: inline-block;
}
</style>

<template>
  <div>
    <v-form @submit.prevent="freshSearch" v-model="valid" ref="form">
      <v-row>
        <v-col class="pb-1">
          <v-text-field
            v-model="searchParams.word"
            label="Rimar con..."
            class="mb-0"
            :rules="wordRules"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pa-0 ma-0" cols="12" md="10">
          <v-row
            class="pa-0 ma-0"
            v-bind:class="{ 'd-none d-md-flex': !displayFilters }"
          >
            <v-col class="pa-2" cols="12" md="3">
              <v-select
                :items="typeOfRhyme"
                item-value="id"
                item-text="name"
                v-model="searchParams.type"
                label="Tipo de rima"
                hide-details
                class="ma-0"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col class="pa-2" cols="12" md="3">
              <v-select
                :items="sortItems"
                item-value="id"
                item-text="name"
                v-model="searchParams.orderBy"
                label="Ordenar por..."
                hide-details
                class="ma-0"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col class="pa-2" cols="12" md="2">
              <v-select
                :items="beginningItems"
                item-value="id"
                item-text="name"
                v-model="searchParams.beginning"
                label="Comienza por..."
                hide-details
                class="ma-0"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col class="pa-2" cols="12" md="2">
              <v-select
                :items="silableItems"
                item-value="id"
                item-text="name"
                v-model="searchParams.silables"
                label="Sílabas"
                hide-details
                class="ma-0"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col class="pa-2" cols="12" md="2">
              <v-select
                :items="categoryItems"
                item-value="id"
                item-text="name"
                v-model="searchParams.category"
                label="Tipo de palabra"
                hide-details
                class="ma-0"
                dense
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="pa-0 ma-0 d-flex d-md-none"
          cols="12"
          v-if="!initialSearch"
        >
          <v-row class="pa-0 ma-0">
            <v-col class="pa-2 pt-0" cols="12">
              <v-btn
                outlined
                block
                color="#273b7a"
                class="filterButton"
                v-show="displayFilters"
                v-on:click="displayFilters = false"
              >
                Ocultar opciones de búsqueda
              </v-btn>
              <v-btn
                outlined
                block
                color="#273b7a"
                class="filterButton"
                v-show="!displayFilters"
                v-on:click="displayFilters = true"
              >
                Mostrar opciones de búsqueda
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col class="pa-0 ma-0" cols="12" md="2">
          <v-row class="pa-0 ma-0">
            <v-col class="pa-2">
              <v-btn
                block
                color="#273b7a"
                style="color: white"
                depressed
                :loading="loading"
                type="submit"
              >
                Buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <div class="mt-5">
      <v-sheet
        v-if="!initialSearch"
        class="resultsSection pa-0 pb-2 mb-3 mt-10 ml-1"
      >
        <span class="font-weight-black"> {{ results.total }} </span> resultados
        encontrados
      </v-sheet>

      <v-sheet
        v-for="word in results.words"
        :key="word.word"
        outlined
        class="pa-2 mb-1 word-block"
        rounded
        v-on:click="loadDefinition(word.original)"
      >
        <div class="px-2 d-inline-flex text--primary">
          {{ word.word }}
        </div>
        <div class="px-2 d-inline-flex text--secondary">
          {{ word.original }}
        </div>
        <div class="px-2 d-inline-flex text--disabled">
          {{ word.type }}
        </div>
      </v-sheet>
      <v-pagination
        v-model="searchParams.page"
        :length="Math.floor(results.total / pageSize) + 1"
        v-show="results.total > pageSize"
        v-on:input="search(false)"
      ></v-pagination>
    </div>
    <v-dialog v-model="showDefinition" scrollable max-width="500px">
      <v-card>
        <v-card-title>{{ definition.lema }}</v-card-title>
        
        <v-divider></v-divider>
        <v-alert
          border="right"
          colored-border
          type="error"
          elevation="2"
          v-if="definition.error"
          style="margin:10px"
        >
          No se ha encontrado una definición para <b>{{ definition.lema }}.</b>
        </v-alert>
        <v-alert
          border="right"
          colored-border
          type="info"
          elevation="2"
          v-if="definition.waiting"
          style="margin:10px"
        >
          Buscando definición...
        </v-alert>
        

        <v-card-text style="height: 300px" v-if="definition.entry">
          
          <div class="entry">
            <ol>
              <li
                class="definition"
                v-for="def in definition.entry.definition"
                :key="def.def + $uuid.v1()"
              >
                <span class="morf" v-if="def.morf">{{ def.morf }}</span>
                <span class="def" v-if="def.def">{{ def.def }} </span>
                <span class="ref" v-if="def.ref"> Ref: {{ def.ref }} </span>
                <span
                  class="example"
                  v-for="(example, index) in def.example"
                  :key="index + $uuid.v1()"
                  >{{ example }}</span
                >
              </li>
            </ol>
          </div>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="#273b7a" text @click="showDefinition = !showDefinition">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="loading"></v-overlay>
  </div>
</template>

<script>
export default {
  name: "diccionario",
  props: {
    results: Object,
    loading: Boolean,
    initialSearch: Boolean,
    definition: Object,
    searchParams: Object
  },
  watch:{
    initialSearch: function(val){
      if(val){
        this.displayFilters = true;
      }
    }
  },
  methods: {
    
    freshSearch(){
      this.search(true);
    },

    search(freshSearch) {
      if (!this.loading && this.$refs.form.validate()) {
        if (this.initialSearch) {
          this.displayFilters = false;
        }
        this.$emit("search-initiated", freshSearch);
      }
    },

    loadDefinition(original) {
      
      this.showDefinition = true;
      this.$emit("definition", original);
    },
  },

  data: () => ({
    pageSize: 500,
    showDefinition: false,
    displayFilters: true,
    valid: false,
    wordRules: [(v) => !!v || "Introduce una palabra con la que rimar"],
    
  
    typeOfRhyme: [
      { id: "c", name: "Consonante" },
      { id: "a", name: "Asonante" },
    ],

    silableItems: [
      { id: "I", name: "Todas" },
      { id: "1", name: "1" },
      { id: "2", name: "2" },
      { id: "3", name: "3" },
      { id: "4", name: "4" },
      { id: "5", name: "5" },
      { id: "6", name: "6" },
      { id: "7", name: "7" },
      { id: "8", name: "8" },
      { id: "9", name: "9" },
      { id: "10", name: "10" },
    ],
    
    sortItems: [
      { id: "R", name: "Frecuencia" },
      { id: "A", name: "Alfabético" },
    ],
 
    beginningItems: [
      { id: "I", name: "Indiferente" },
      { id: "true", name: "Vocal o H" },
      { id: "false", name: "Consonante" },
    ],
    
    categoryItems: [
      { id: "I", name: "Todos" },
      { id: "0", name: "Sust. y adj." },
      { id: "1", name: "Verbos" },
      { id: "2", name: "Participios" },
    ],
  }),
};
</script>


<style scoped>
</style>
